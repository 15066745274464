<!--
--------------------------------------------------------------------------------
<copyright file="Expand.vue" company="Bystronic Laser AG">
 Copyright (C) Bystronic Laser AG 2021-2024
</copyright>
--------------------------------------------------------------------------------
-->

<template>
  <details>
    <summary>
      <p class="list">{{ $t('widget_info_text.expand') }}</p>
    </summary>
    <img src="images/info/expand.jpg" class="list-item" />
    <p class="list-item text">{{ $t('widget_info_text.expand_txt') }}</p>
  </details>
</template>

<script language="ts">
import { Component, Vue } from 'vue-property-decorator';
@Component
export default class Expand extends Vue {}
</script>
