<!--
--------------------------------------------------------------------------------
<copyright file="LaserCuttingTime.vue" company="Bystronic Laser AG">
 Copyright (C) Bystronic Laser AG 2021-2024
</copyright>
--------------------------------------------------------------------------------
-->

<template>
  <section>
    <details>
      <summary>{{ $t('widget_info_text.outer_arc') }}</summary>
      <p class="text demi-bold">{{ $t('widget_info_text.current_value') }}</p>
      <p class="text">{{ $t('widget_info_text.outer_arc_cut_time_txt') }}</p>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.inner_arc') }}</summary>
      <p class="text demi-bold">{{ $t('widget_info_text.inner_arc_cut_time_txt') }}</p>
      <p class="text">{{ $t('widget_info_text.inner_arc_cut_time_txt2') }}</p>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.current_previous_val') }}</summary>
      <p class="text">{{ $t('widget_info_text.current_previous_val_txt') }}</p>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.clock_hand') }}</summary>
      <p class="text">{{ $t('widget_info_text.clock_hand_cut_time_txt') }}</p>
      <p class="info list-item info-bkg">{{ $t('widget_info_text.info') }}:</p>
      <p class="text info-bkg">{{ $t('widget_info_text.clock_hand_info_txt') }}</p>
      <p class="tip list-item tip-bkg">{{ $t('widget_info_text.tip') }}:</p>
      <p class="text tip-bkg">{{ $t('widget_info_text.tooltip_txt') }}</p>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.icons') }}</summary>
      <time-filter />
      <shift-filter />
      <expand-btn />
      <details>
        <summary>
          <span class="demi-bold list">{{ $t('widget_info_text.more_filter') }}</span>
        </summary>
        <img src="images/info/threeDotsMenu.jpg" class="list" />
        <target-filter />
      </details>
    </details>
  </section>
</template>

<script language="ts">
import { Component, Vue } from 'vue-property-decorator';
import TimeFilterInfo from '@/components/Charts/widgetInfo/TimeFilter.vue';
import ShiftFilterInfo from '@/components/Charts/widgetInfo/ShiftFilter.vue';
import TargetFilterInfo from './TargetFilter.vue';
import Expand from '@/components/Charts/widgetInfo/Expand.vue';

@Component({
  components: {
    'time-filter': TimeFilterInfo,
    'shift-filter': ShiftFilterInfo,
    'target-filter': TargetFilterInfo,
    'expand-btn': Expand,
  },
})
export default class LaserCuttingTime extends Vue {}
</script>
